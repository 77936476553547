import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function ModernTableForTarenr() {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>مؤشرات التقييم</TableCell>
            <TableCell>ضعيف</TableCell>
            <TableCell>وسط</TableCell>
            <TableCell>جيد</TableCell>
            <TableCell>ممتاز</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>يملك خبرة سابقة</TableCell>
            <TableCell>لا يملك خبرة سابقة</TableCell>
            <TableCell>من سنة إلى 3 سنوات</TableCell>
            <TableCell>من 4 إلى 8 سنوات</TableCell>
            <TableCell>أكثر من 8 سنوات</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>تلقي وفهم المعلومات</TableCell>
            <TableCell>يحتاج إعادة المعلومات كثيراً</TableCell>
            <TableCell>يتلقى 50% من المعلومات</TableCell>
            <TableCell>يتلقى 75% من المعلومات</TableCell>
            <TableCell>يتلقى المعلومات بسهولة</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>يشارك بالنشاطات خلال الجلسات</TableCell>
            <TableCell>لا يشارك بالنشاطات</TableCell>
            <TableCell>يشارك إذا طلب منه</TableCell>
            <TableCell>يشارك أحياناً</TableCell>
            <TableCell>يشارك دائماً</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>متفاعل مع المدرب</TableCell>
            <TableCell>لا يقوم بأية مداخلات</TableCell>
            <TableCell>يشارك إذا طلب منه</TableCell>
            <TableCell>يشارك أحياناً</TableCell>
            <TableCell>يشارك دائماً</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>يأخذ الدورة على محمل الجد</TableCell>
            <TableCell>أبداً</TableCell>
            <TableCell>نادراً</TableCell>
            <TableCell>أحياناً</TableCell>
            <TableCell>دائماً</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>يقدم المساعدة للمستفيدين الآخرين</TableCell>
            <TableCell>أبداً</TableCell>
            <TableCell>في حال طلب منه</TableCell>
            <TableCell>أحياناً</TableCell>
            <TableCell>دائماً</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ModernTableForTarenr;
