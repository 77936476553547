import React, { useEffect, useState } from 'react'
import CustomizedTablesForprequestions from '../Component/CustomizedTablesForprequestions'

import CustomizedTablesForafterquestions from '../Component/CustomizedTablesForafterquestions'
import CustomizedTablesForRatings from '../Component/CustomizedTablesForRatings'
import axios from 'axios'


function Home() {
  const [loading , setLoading] = useState(false)

  const fetchQuerstions = async () => {
    
  }

  useEffect(() => {
    fetchQuerstions()
  } , [])

  return (
    <div> 
    <h1> الاستبيانات</h1>
    <CustomizedTablesForprequestions/>  
    
    <h1> التقييم</h1>
    <CustomizedTablesForRatings/>  
    </div>
  )
}

export default Home
