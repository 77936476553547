import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, Slider, Paper, TextField } from '@mui/material';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import domtoimage from 'dom-to-image';
import logo from './Client/SBK Logo.png';

const labels = {
  1: 'ضعيف',
  2: 'متوسط',
  3: 'جيد',
  4: 'جيد جدا',
  5: 'ممتاز',
};

function RatingPrint() {
  const [questions, setQuestions] = useState([]);
  const [name, setName] = useState('');
  const [courseName, setCourseName] = useState('');
  const [teacherName, setTeacherName] = useState('');
  const [courseDate, setCourseDate] = useState('');
  const [center, setCenter] = useState('');
  const [note, setNote] = useState('');
  const headersRef = useRef();
  const questionRefs = useRef([]);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem('RatingPrint'));
    if (localStorageData) {
      setName(localStorageData.student_name);
      setCourseName(localStorageData.course_name);
      setTeacherName(localStorageData.teacher_name);
      setCourseDate(localStorageData.course_date);
      setCenter(localStorageData.center);
      setNote(localStorageData.note);
      const formattedQuestions = localStorageData.answers.map((answer, index) => ({
        id: answer.teacher_question,
        text: answer.teacher_question,
        rating: parseInt(answer.value),
        ref: React.createRef()
      }));
      setQuestions(formattedQuestions);
    }
  }, []);

  const downloadDocx = async () => {
    const headerImage = await domtoimage.toPng(headersRef.current);
    const questionImages = await Promise.all(
      questions.map(question => domtoimage.toPng(question.ref.current))
    );

    const content = `
      <div dir="rtl" style="text-align: center; direction: rtl;">
        <img src="${headerImage}" style="width: 100%;" />
        ${questionImages.map(img => `<img src="${img}" style="width: 100%; margin-top: 10px;" />`).join('')}
      </div>
    `;

    const converted = htmlDocx.asBlob(content);
    saveAs(converted, `${courseName} - تقييم.docx`);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', my: 4 }}>
      <div ref={headersRef}>
        <img src={logo} width='200px' height="200px"/>
        <Typography variant="h4" component="h1" gutterBottom>تقييم فردي للمستفيد</Typography>
        <TextField fullWidth label="اسم المستفيد" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} sx={{ mb: 2 }} />
        <TextField fullWidth label="اسم الدورة" variant="outlined" value={courseName} onChange={(e) => setCourseName(e.target.value)} sx={{ mb: 2 }} />
        <TextField fullWidth label="اسم المدرب" variant="outlined" value={teacherName} onChange={(e) => setTeacherName(e.target.value)} sx={{ mb: 2 }} />
        <TextField fullWidth label="تاريخ الدورة" variant="outlined" value={courseDate} onChange={(e) => setCourseDate(e.target.value)} sx={{ mb: 2 }} />
        <TextField fullWidth label="اسم المركز" variant="outlined" value={center} onChange={(e) => setCenter(e.target.value)} sx={{ mb: 2 }} />
        <TextField fullWidth label="الملاحظات" variant="outlined" value={note} onChange={(e) => setNote(e.target.value)} sx={{ mb: 2 }} />
      </div>
      {questions.map((question, index) => (
        <Paper key={index} variant="outlined" sx={{ p: 2, mb: 2 }} ref={question.ref}>
          <Typography variant="h6">{question.text}</Typography>
          <Slider
            value={question.rating}
            step={1}
            marks={[
              { value: 1, label: 'ضعيف' },
              { value: 2, label: 'وسط' },
              { value: 3, label: 'جيد' },
              { value: 4, label: 'جيد جدا' },
              { value: 5, label: 'ممتاز' },
            ]}
            min={1}
            max={5}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => labels[value]}
            sx={{ marginTop: 2 }}
            disabled={true}
          />
        </Paper>
      ))}
      <Button variant="contained" color="primary" onClick={downloadDocx}>Download Word</Button>
    </Box>
  );
}

export default RatingPrint;
