    import * as React from 'react';
    import { useState } from 'react';
    import Avatar from '@mui/material/Avatar';
    import Button from '@mui/material/Button';
    import CssBaseline from '@mui/material/CssBaseline';
    import TextField from '@mui/material/TextField';
    import FormControlLabel from '@mui/material/FormControlLabel';
    import Checkbox from '@mui/material/Checkbox';
    import Link from '@mui/material/Link';
    import Grid from '@mui/material/Grid';
    import Box from '@mui/material/Box';
    import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
    import Typography from '@mui/material/Typography';
    import Container from '@mui/material/Container';
    import Alert from '@mui/material/Alert';
    import { createTheme, ThemeProvider } from '@mui/material/styles';
    import axios from 'axios';
    import CircularProgress from '@mui/material/CircularProgress';

    const defaultTheme = createTheme();

    export default function SignIn() {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);  // Start loading
        const data = new FormData(event.currentTarget);
        const payload = {
          email: data.get('email'),
          password: data.get('password'),
        };
      
        axios.post('https://api.sbk-questionnaire.com/api/login', payload)
          .then(response => {
            console.log('Login successful:', response.data);
            localStorage.setItem('access_token', response.data.access_token);  // Save the token
            setLoading(false);  // Stop loading
            setError('');  // Clear any previous errors
            window.location.href = '/home';  // Redirect to the dashboard
            // Redirect or perform other actions upon successful login
          })
          .catch(error => {
            setLoading(false);  // Stop loading on error
            const status = error.response ? error.response.status : 500;  // Default to 500 if no response status
            switch (status) {
              case 400:
                setError('Invalid request. Please enter valid email and password.');
                break;
              case 401:
                setError('Unauthorized. Please check your credentials.');
                break;
              case 404:
                setError('Server not found. Please try again later.');
                break;
              case 500:
                setError('Internal server error. Please try again later.');
                break;
              default:
                setError('An unexpected error occurred. Please try again.');
            }
          });
      };
      
    return (
        <ThemeProvider theme={defaultTheme} >
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {error && <Alert severity="error">{error}</Alert>}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Sign In'}
            </Button>
          </Box>
          
            </Box>
        </Container>
        </ThemeProvider>
    );
    }
