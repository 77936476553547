import React, { useRef, useEffect } from 'react';
import { Button, Box, Typography, Paper } from '@mui/material';
import domtoimage from 'dom-to-image';
import logo from './Client/SBK Logo.png';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { IoMdCheckmark } from "react-icons/io";
import { FaXmark } from "react-icons/fa6";
import moment from 'moment';

function QuestereForPrint() {
  const printRef = useRef();
  const detailsRef = useRef();
  const questionRefs = useRef([]);
  const [title, setTitle] = React.useState('');
  const [questions, setQuestions] = React.useState([]);
  const [beneficiaryName, setBeneficiaryName] = React.useState('');
  const [courseName, setCourseName] = React.useState('');
  const [courseDate, setCourseDate] = React.useState('');
  const [techerName, setTecherName] = React.useState('');
  const [submitionData, setSubmissionDate] = React.useState('');
  const [Titlee, setTitlee] = React.useState(null);

  const [center, setCenter] = React.useState('');
  const [totalMark, setTotalMark] = React.useState(0);
  const logoRef = useRef();  // Reference for the logo

  useEffect(() => {
    const studentData = JSON.parse(localStorage.getItem('studentData'));
    if (studentData) {
      setTitle(studentData.course_name);
      setQuestions(studentData.questions_answer);
      setBeneficiaryName(studentData.username);
      setCourseName(studentData.course_name);
      setCourseDate(studentData.course_date);
      setCenter(studentData.center);
      setTitlee(studentData.questions_answer[0].poll_name[0]?.poll_title);
      
      setSubmissionDate(moment(studentData.created_at).format('LL')); // Formats date as 'June 30, 2024'
      setTotalMark(studentData.total_mark);
      setTecherName(studentData.techer_name);
    }
  }, []);

  const downloadDocx = async () => {
    if (!printRef.current) {
      console.error("Element to capture is not found");
      return;
    }

    try {
      const logoImage = await domtoimage.toPng(logoRef.current);
      const detailsImage = await domtoimage.toPng(detailsRef.current);
      const questionImages = await Promise.all(
        questionRefs.current.map((ref) => domtoimage.toPng(ref))
      );

      const content = `
        <div dir="rtl" style="text-align: center; direction: rtl;">
          <img src="${logoImage}" alt="Logo" style="width: 200px; height: 200px;" />
          <img src="${detailsImage}" alt="Details" />
          ${questionImages.map(img => `<img src="${img}" alt="Question" style="width: 100%; margin-top: 10px;" />`).join('')}
          <p style="font-size: 20px;">${totalMark} :العلامة الكلية</p>
        </div>
      `;

      const converted = htmlDocx.asBlob(content);
      saveAs(converted, 'ExportedDocument.docx');
    } catch (error) {
      console.error("Error creating document:", error);
    }
  };

  return (
    <div ref={printRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', direction: 'rtl', fontFamily: 'Arial, sans-serif' }} id='contentToCapture'>
      <img ref={logoRef}  id="logoImage" src={logo} width='200px' height="200px" alt="Logo" style={{ marginBottom: '20px' }} />
      <Box dir="rtl" sx={{ mx: 'auto', my: 4, textAlign: 'right', width: '800px' }}>
   
    
        <div ref={detailsRef} style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "right", width: '100%' }}>
        <div style={{ display: "flex", justifyContent: "center", width: '100%' }}>
        <Typography variant="h6" style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center', // Center text alignment
          width: '800px' // This sets the width of the Typography element
        }}>
          <span style={{ fontWeight: 'bold' }}>{Titlee}</span>
        </Typography>
      </div>
          <Typography variant="h6" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '800px' }}>
            <span style={{ fontWeight: 'bold' }}>اسم المستفيد :</span> {beneficiaryName}
          </Typography>

          <Typography variant="h6" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '800px' }}>
          <span style={{ fontWeight: 'bold' }}>اسم الدورة :</span> {courseName}
        </Typography>

        <Typography variant="h6" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '800px' }}>
        <span style={{ fontWeight: 'bold' }}>تاريخ الدورة :</span> {courseDate}
      </Typography>
      <Typography variant="h6" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '800px' }}>
      <span style={{ fontWeight: 'bold' }}>تاريخ الاستبيان :</span> {submitionData}
    </Typography>

      
      <Typography variant="h6" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '800px' }}>
      <span style={{ fontWeight: 'bold' }}>اسم المدرب :</span> {techerName}
    </Typography>
    <Typography variant="h6" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '800px' }}>
    <span style={{ fontWeight: 'bold' }}>المركز  :</span> {center}
  </Typography>
  <Typography variant="h6" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '800px' }}>
  <span style={{ fontWeight: 'bold' }}>العلامة الكلية :</span> {totalMark}
</Typography>
        </div>

        <Typography variant="h6" style={{ textAlign: 'right' }}>الاسئلة:</Typography>
        {questions.slice().reverse().map((question, index) => (
          <Paper key={index} variant="outlined" sx={{ p: 1, mb: 1, textAlign: 'right', width: '100%' }} ref={(el) => (questionRefs.current[index] = el)}>
            <Typography variant="h6" sx={{ fontSize: '16px', textAlign: 'right' }}>
              {question.question_title}
            </Typography>
            {question.answers.map((option, optionIndex) => (
              <Box
                key={optionIndex}
                sx={{
                  p: 1,
                  cursor: question.selected_answer_id === null ? 'pointer' : 'not-allowed',
                  backgroundColor: question.selected_answer_id === option.id ? 'gray' : 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: '14px' }}>{option.title}</Typography>
                {question.selected_answer_id === option.id && option.is_correct === 1 ?
                  <IoMdCheckmark style={{ color: 'green', fontSize: '20px', marginLeft: '10px' }} /> :
                  question.selected_answer_id === option.id && option.is_correct === 0 ?
                    <FaXmark style={{ color: 'red', fontSize: '20px', marginLeft: '10px' }} />
                    : ""}
              </Box>
            ))}
          </Paper>
        ))}
        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
          {totalMark} :العلامة الكلية
        </Typography>

        <Button variant="contained" color="secondary" onClick={downloadDocx} sx={{ mt: 2 }}>
          Download Word
        </Button>
      </Box>
    </div>
  );
}

export default QuestereForPrint;
