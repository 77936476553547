import React, { useRef, useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Paper, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { styled, keyframes } from '@mui/system';
import logo from './SBK Logo.png'
const rotate = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const AnimatedText = styled(Typography)({
  animation: `${rotate} 2s ease-in-out infinite`,
});


const defaultQuestions = [
  {
    id: 1,
    text: 'ما هو لونك المفضل؟',
    options: [
      { text: 'أحمر', isCorrect: false },
      { text: 'أزرق', isCorrect: true },
      { text: 'أخضر', isCorrect: false },
      { text: 'أصفر', isCorrect: false }
    ],
    selectedAnswer: null,
    answerCorrect: null
  },
  {
    id: 2,
    text: 'ما هو حيوانك المفضل؟',
    options: [
      { text: 'كلب', isCorrect: true },
      { text: 'قط', isCorrect: false },
      { text: 'طائر', isCorrect: false },
      { text: 'سمكة', isCorrect: false }
    ],
    selectedAnswer: null,
    answerCorrect: null
  },
];

function AfterQuestioere() {
    const location = useLocation();
    const printRef = useRef();
    const { data } = location.state || { data: { ID: 'غير متاح', name: 'غير متاح', total: 'غير متاح', Date: 'غير متاح' } };
    const [questions, setQuestions] = useState(() => {
      const savedQuestions = JSON.parse(localStorage.getItem('afterquestions'));
      return savedQuestions || defaultQuestions;
    });
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      localStorage.setItem('afterquestions', JSON.stringify(questions));
      setTimeout(() => setLoading(false), 3000); // Simulate loading for 3 seconds
    }, [questions]);

  const handleOptionChange = (questionId, option) => {
    const updatedQuestions = questions.map(q => {
      if (q.id === questionId && q.selectedAnswer === null) {
        return {
          ...q,
          selectedAnswer: option.text,
          answerCorrect: option.isCorrect
        };
      }
      return q;
    });
    setQuestions(updatedQuestions);
  };

  const applyAnswerStyle = (question, option) => {
    if (question.selectedAnswer === option.text) {
      return {
        backgroundColor: question.answerCorrect ? 'lightgreen' : 'red',
        animation: question.answerCorrect ? 'sprinkles 0.5s linear' : 'shake 0.5s linear'
      };
    }
    return {};
  };

  const downloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
    });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width, canvas.height]
    });
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    pdf.save("questionnaire.pdf");
  };

  const hasAccessToken = localStorage.getItem('access_token');

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img src={logo} alt="Logo" style={{ width: 120, height: 120 }} />
      <AnimatedText variant="h4" sx={{ mt: 2 }}>Arbaji SBK L.L.C was established in 2017 in Damascus</AnimatedText>
    </Box>
    );
  }

  return (
    <Box>
      <Box ref={printRef} sx={{ maxWidth: 600, mx: 'auto', my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          استبيان
        </Typography>
        <TextField
          fullWidth
          label="أدخل اسمك"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />
        {questions.map((question, index) => (
          <Paper key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6">{question.text}</Typography>
            {question.options.map((option) => (
              <Box key={option.text} sx={{ p: 2, cursor: question.selectedAnswer === null ? 'pointer' : 'not-allowed', ...applyAnswerStyle(question, option) }}
                onClick={() => {
                  if (question.selectedAnswer === null) {
                    handleOptionChange(question.id, option);
                  }
                }}
              >
                <Typography>{option.text}</Typography>
              </Box>
            ))}
          </Paper>
        ))}
      </Box>
      {hasAccessToken? (
          <Button variant="contained" color="secondary" onClick={downloadPdf} sx={{ mt: 2 }}>
            تحميل PDF
          </Button>
        ): <Button variant="contained" color="primary" onClick={() => alert('تم الإرسال بنجاح!')} disabled={!name.trim()}>
        إرسال
      </Button>}
    </Box>
  );
}

export default AfterQuestioere;
