import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';

// Import components
import SignIn from './Pages/LogIn';
import Home from './Pages/Home';
import NaveBare from './Component/NaveBare';
import Questions from './Pages/Questions';
import Taqeem from './Pages/Taqeem';
import Questioere from './Pages/Client/Questioere';
import Ratings from './Pages/Client/Ratings';
import AfterQuestioere from './Pages/Client/afterQuestioere';
import Poll from './Pages/Poll';
import Questionnaire from './Pages/Client/Questioere';
import QuestereForPrint from './Pages/QuestereForPrint';
import RatingPrint from './Pages/RatingPrint';

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

function App() {
    const location = useLocation();
    const hideNavPaths = ['/Questioere', '/afterQuestioere', '/Ratings','/','/Questionnaire'];
    const showNavBar = !hideNavPaths.includes(location.pathname);

    return (
        <div className="App">
            {showNavBar && <NaveBare />}
            
            <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/home" element={<Home />} />
                <Route path="/prequestions" element={<Questions />} />
                <Route path="/taq" element={<Taqeem />} />
                <Route path="/Questionnaire" element={<Questionnaire />} />
                <Route path="/Questionnaireprint" element={<QuestereForPrint />} />
                <Route path="/afterQuestioere" element={<AfterQuestioere />} />
                <Route path="/RatingPrint" element={<RatingPrint />} />
                <Route path="/Ratings" element={<Ratings />} />
                <Route path="/Poll" element={<Poll />} />
            </Routes>
        </div>
    );
}

export default AppWrapper;
