import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  }, 
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function CustomizedTablesForprequestions() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [teacherNameFilter, setTeacherNameFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [pollTitleFilter, setPollTitleFilter] = useState('');

  useEffect(() => {
    axios.get('https://api.sbk-questionnaire.com/api/userSubmission')
      .then(response => {
        console.log('response.data.data',response.data.data)
        const data = response.data.data.map(item => ({
          ...item,
          ID: item.id,
          name: item.username,
          total: item.total_mark,
          course_name: item.course_name,
          Date: item.course_date
        }));
        setRows(data);
        setFilteredRows(data);
      })
      .catch(error => {
        console.error('Error fetching user submissions:', error);
      });
  }, []);
// studentData.questions_answer[0].poll_name[0]?.poll_title
useEffect(() => {
  const filtered = rows.filter(row => {
    const rowDate = new Date(row.Date);
    const startDate = startDateFilter ? new Date(startDateFilter) : new Date('1970-01-01'); // default to a very old date if not set
    const endDate = endDateFilter ? new Date(endDateFilter) : new Date(); // default to current date if not set
    const pollTitle = row.questions_answer[0]?.poll_name[0]?.poll_title || ''; // Safely access the nested property

    return (teacherNameFilter ? row.techer_name.includes(teacherNameFilter) : true) &&
           (!startDateFilter || rowDate >= startDate) &&
           (!endDateFilter || rowDate <= endDate) &&
           (pollTitleFilter ? pollTitle.toLowerCase().includes(pollTitleFilter.toLowerCase()) : true);
  });
  setFilteredRows(filtered);
}, [teacherNameFilter, startDateFilter, endDateFilter, pollTitleFilter, rows]);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (row) => {
    localStorage.setItem('studentData', JSON.stringify(row));
    navigate('/Questionnaireprint', { state: { data: row } });
  };

  return (
    <Paper>
    <div style={{ padding: '16px' }}>
    <TextField
  label="Filter by Poll Title"
  value={pollTitleFilter}
  onChange={e => setPollTitleFilter(e.target.value)}
  variant="outlined"
  style={{ marginRight: '8px' }}
/>
  <TextField
    label="Filter by Teacher Name"
    value={teacherNameFilter}
    onChange={e => setTeacherNameFilter(e.target.value)}
    variant="outlined"
    style={{ marginRight: '8px' }}
  />
  <TextField
    label="Start Date"
    type="date"
    value={startDateFilter}
    onChange={e => setStartDateFilter(e.target.value)}
    variant="outlined"
    InputLabelProps={{
      shrink: true,
    }}
    style={{ marginRight: '8px' }}
  />
  <TextField
    label="End Date"
    type="date"
    value={endDateFilter}
    onChange={e => setEndDateFilter(e.target.value)}
    variant="outlined"
    InputLabelProps={{
      shrink: true,
    }}
  />
</div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>المعرف</StyledTableCell>
              <StyledTableCell align="right">الاسم</StyledTableCell>
              <StyledTableCell align="right">العلامة الكلية</StyledTableCell>
              <StyledTableCell align="right">اسم الدورة</StyledTableCell>
              <StyledTableCell align="right">اسم المدرب</StyledTableCell>
              <StyledTableCell align="right">التاريخ</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row.ID} hover onClick={() => handleRowClick(row)}>
                <StyledTableCell component="th" scope="row">{row.ID}</StyledTableCell>
                <StyledTableCell align="right">{row.name}</StyledTableCell>
                <StyledTableCell align="right">{row.total}</StyledTableCell>
                <StyledTableCell align="right">{row.course_name}</StyledTableCell>
                <StyledTableCell align="right">{row.techer_name}</StyledTableCell>
                <StyledTableCell align="right">{row.Date}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default CustomizedTablesForprequestions;
