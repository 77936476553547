import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import StarRateIcon from '@mui/icons-material/StarRate';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import Logo from '../Pages/Client/SBK Logo.png';

export default function NavBar() {
  const [value, setValue] = useState(parseInt(localStorage.getItem('navTab'), 10) || 0);
  const navigate = useNavigate();

  useEffect(() => {
    // Reactively update navigation tab from local storage
    const handleTabChange = () => {
      const storedTab = parseInt(localStorage.getItem('navTab'), 10);
      setValue(!isNaN(storedTab) ? storedTab : 0);
    };
    window.addEventListener('storage', handleTabChange);
    return () => window.removeEventListener('storage', handleTabChange);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('navTab', newValue.toString());
    switch (newValue) {
      case 0:
        navigate('/home');
        break;
      case 1:
        navigate('/prequestions');
        break;
      case 2:
        navigate('/taq');
        break;
      case 3:
        navigate('/Poll');
        break;
      case 4:
        localStorage.removeItem('navTab');
        navigate('/');
        break;
      default:
        console.warn('Unknown tab index', newValue);
        break;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: { xs: 'column', md: 'row' }, // Column layout on small screens, row layout on medium and larger screens
      }}
    >
      <img
        src={Logo}
        alt='logo'
        style={{
          width: '100px',
          marginBottom: { xs: '10px', md: 0 }, // Add margin at the bottom only on small screens
        }}
      />
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleChange}
      >
        <BottomNavigationAction label="الرئيسية" icon={<HomeIcon />} />
        <BottomNavigationAction label="الاسئلة" icon={<QuestionAnswerIcon />} />
        <BottomNavigationAction label="التقييم" icon={<StarRateIcon />} />
        <BottomNavigationAction label="الاستبيانات" icon={<QuestionAnswerIcon />} />
        <BottomNavigationAction label="تسجيل الخروج" icon={<ExitToAppIcon />} />
      </BottomNavigation>
    </Box>
  );
}
