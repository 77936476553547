import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  }, 
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function CustomizedTablesForRatings() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [teacherNameFilter, setTeacherNameFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  useEffect(() => {
    axios.get('https://api.sbk-questionnaire.com/api/teachers')
      .then(response => {
        const data = response.data.data.map(item => ({
          ...item,
          ID: item.id,
          teacher_name: item.teacher_name,
          student_name: item.student_name,
          Date: item.course_date
        }));
        setRows(data);
        setFilteredRows(data);
      })
      .catch(error => {
        console.error('Error fetching teacher data:', error);
      });
  }, []);

  useEffect(() => {
    const filtered = rows.filter(row => {
      return (teacherNameFilter ? row.teacher_name.includes(teacherNameFilter) : true) &&
             (dateFilter ? row.Date === dateFilter : true);
    });
    setFilteredRows(filtered);
  }, [teacherNameFilter, dateFilter, rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (row) => {
    localStorage.setItem('RatingPrint', JSON.stringify(row));
    navigate('/RatingPrint', { state: { data: row } });
  };

  return (
    <Paper>
      <div style={{ padding: '16px' }}>
        <TextField
          label="Filter by Teacher Name"
          value={teacherNameFilter}
          onChange={e => setTeacherNameFilter(e.target.value)}
          variant="outlined"
          style={{ marginRight: '8px' }}
        />
        <TextField
          label="Filter by Date"
          type="date"
          value={dateFilter}
          onChange={e => setDateFilter(e.target.value)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>المعرف</StyledTableCell>
              <StyledTableCell align="right">اسم المدرب</StyledTableCell>
              <StyledTableCell align="right">اسم المستفيد</StyledTableCell>
              <StyledTableCell align="right">التاريخ</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow key={row.ID} hover onClick={() => handleRowClick(row)}>
                <StyledTableCell component="th" scope="row">{row.ID}</StyledTableCell>
                <StyledTableCell align="right">{row.teacher_name}</StyledTableCell>
                <StyledTableCell align="right">{row.student_name}</StyledTableCell>
                <StyledTableCell align="right">{row.Date}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default CustomizedTablesForRatings;
