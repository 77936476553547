import React, { useState, useEffect } from 'react';
import { TextField, Button, Card, CardContent, Typography, Box, IconButton, Modal } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

function Taqeem() {
  const [questions, setQuestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newQuestionText, setNewQuestionText] = useState('');

async function fetchData(){
     await axios.get('https://api.sbk-questionnaire.com/api/teacherQuestion')
      .then(response => {
        console.log(response)
        const fetchedQuestions = Array.isArray(response.data.data) ? response.data.data : [];
        setQuestions(fetchedQuestions);
      })
      .catch(error => {
        console.error('There was an error fetching the questions!', error);
      });
}

  useEffect(() => {
   
    
    fetchData()
  }, []);

  const addQuestion = () => {
    const newQuestion = {
      id: questions.length + 1,
      text: newQuestionText,
      answers: [],
      correctAnswer: null
    };

    setQuestions([...questions, newQuestion]);
    setIsModalOpen(false);
    setNewQuestionText('');
    const formData = new FormData();
    formData.append('question', newQuestion.text);
    // Optionally, send the new question to the server
    axios.post('https://api.sbk-questionnaire.com/api/teacherQuestion', formData)
      .then(response => {
        // Update the local state with the ID returned from the server
        setQuestions([...questions, response.data]);
        fetchData()
      })
      .catch(error => {
        console.error('There was an error adding the question!', error);
      });
  };

  const updateQuestion = (index, updatedQuestion) => {
    const updatedQuestions = questions.map((question, i) => {
      if (i === index) {
        return updatedQuestion;
      }
      return question;
    });
    setQuestions(updatedQuestions);

    // Update the question on the server
    axios.put(`https://api.sbk-questionnaire.com/api/teacherQuestion/${updatedQuestion.id}`, updatedQuestion)
      .catch(error => {
        console.error('There was an error updating the question!', error);
      });
  };

  const deleteQuestion = (id) => {
    setQuestions(questions.filter(question => question.id !== id));

    // Delete the question from the server
    axios.delete(`https://api.sbk-questionnaire.com/api/teacherQuestion/${id}`)
    //fetchData()
      .catch(error => {
        console.error('There was an error deleting the question!', error);
      });
  };

  function QuestionEditor({ question, updateQuestion, deleteQuestion }) {
    const [localQuestion, setLocalQuestion] = useState(question);

    const handleQuestionChange = (event) => {
      setLocalQuestion({ ...localQuestion, text: event.target.value });
    };

    const addAnswer = () => {
      const newAnswer = { id: localQuestion.answers.length + 1, text: '' };
      setLocalQuestion({
        ...localQuestion,
        answers: [...localQuestion.answers, newAnswer]
      });
    };

    const updateAnswer = (index, text) => {
      const updatedAnswers = localQuestion.answers.map((answer, i) => {
        if (i === index) {
          return { ...answer, text };
        }
        return answer;
      });
      setLocalQuestion({ ...localQuestion, answers: updatedAnswers });
    };

    const markCorrectAnswer = (index) => {
      setLocalQuestion({ ...localQuestion, correctAnswer: index });
    };

    const saveChanges = () => {
      updateQuestion(localQuestion);
    };

    return (
      <Card variant="outlined" sx={{ marginBottom: 2 }}>
        <CardContent>
          <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              label="التقييم"
              value={localQuestion.question}
              onChange={handleQuestionChange}
              variant="outlined"
              margin="normal"
            />
            <IconButton onClick={() => deleteQuestion(question.id)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Box>
          {/* You can add answer editing UI here */}
          <Button onClick={saveChanges} variant="contained" color="primary">
            حفظ
          </Button>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        ادارة التقييمات
      </Typography>
      {Array.isArray(questions) && questions.map((question, index) => (
        <QuestionEditor
          key={question.id}
          question={question}
          updateQuestion={(updatedQuestion) => updateQuestion(index, updatedQuestion)}
          deleteQuestion={deleteQuestion}
        />
      ))}
      <Button onClick={() => setIsModalOpen(true)} variant="contained" color="primary">
        اضافة تقييم
      </Button>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          width: 400, 
          bgcolor: 'background.paper', 
          border: '2px solid #000', 
          boxShadow: 24, 
          p: 4 
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            اضافة تقييم جديد
          </Typography>
          <TextField
            fullWidth
            label="اسم التقييم"
            value={newQuestionText}
            onChange={(e) => setNewQuestionText(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <Button onClick={addQuestion} variant="contained" color="primary" sx={{ mt: 2 }}>
            حفظ
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default Taqeem;
