import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Modal, MenuItem, Select, InputLabel, FormControl, IconButton, TextField, Card, CardContent } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Poll() {
  const [polls, setPolls] = useState([]);
  const [isPollModalOpen, setIsPollModalOpen] = useState(false);
  const [newPollName, setNewPollName] = useState('');
  const [questions, setQuestions] = useState([]);
  const [pollQuestions, setPollQuestions] = useState([{ question: '', points: '' }]);
  const [selectedPollIndex, setSelectedPollIndex] = useState(null);

  useEffect(() => {
    axios.get('https://api.sbk-questionnaire.com/api/questions')
      .then(response => {
        console.log(response.data.data)
        if (Array.isArray(response.data.data)) {
          setQuestions(response.data.data);
        } else {
          console.error('The response is not an array:', response.data);
          setQuestions([]);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the questions!', error);
        setQuestions([]);
      });

    axios.get('https://api.sbk-questionnaire.com/api/polls')
      .then(response => {
        console.log(response.data.data)
        if (Array.isArray(response.data.data)) {
          setPolls(response.data.data);
        } else {
          console.error('The response is not an array:', response.data);
          setPolls([]);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the polls!', error);
        setPolls([]);
      });
  }, []);

  const handlePollQuestionChange = (index, field, value) => {
    const updatedPollQuestions = [...pollQuestions];
    updatedPollQuestions[index][field] = value;
    setPollQuestions(updatedPollQuestions);
  };

  const addPollQuestion = () => {
    setPollQuestions([...pollQuestions, { question: '', points: '' }]);
  };

  const removePollQuestion = (index) => {
    setPollQuestions(pollQuestions.filter((_, i) => i !== index));
  };

  const addPoll = () => {
    const newPoll = {
      title: newPollName,
      questions: pollQuestions.map(({ question, points }) => ({
        question_id: question,
        mark: points
      }))
    };

    axios.post('https://api.sbk-questionnaire.com/api/polls', newPoll)
      .then(response => {
        setPolls([...polls, response.data]);
        setNewPollName('');
        setPollQuestions([{ question: '', points: '' }]);
        setIsPollModalOpen(false);
      })
      .catch(error => {
        console.error('There was an error adding the poll!', error);
      });
  };

  const updatePoll = () => {
    const updatedPoll = {
      title: newPollName
    //   questions: polls[selectedPollIndex].questions.map(({ question_id, mark }) => ({
    //     question_id,
    //     mark
    //   }))
    };
  
    axios.patch(`https://api.sbk-questionnaire.com/api/polls/${polls[selectedPollIndex].id}`, updatedPoll)
      .then(response => {
        const updatedPolls = [...polls];
        updatedPolls[selectedPollIndex] = response.data;
        setPolls(updatedPolls);
        setNewPollName('');
        setIsPollModalOpen(false);
        setSelectedPollIndex(null);
      })
      .catch(error => {
        console.error('There was an error updating the poll!', error);
      });
  };
  

  const deletePoll = (index) => {
    axios.delete(`https://api.sbk-questionnaire.com/api/polls/${polls[index].id}`)
      .then(() => {
        setPolls(polls.filter((_, i) => i !== index));
      })
      .catch(error => {
        console.error('There was an error deleting the poll!', error);
      });
  };

  const openEditModal = (index) => {
    setSelectedPollIndex(index);
    setNewPollName(polls[index].title);
    setPollQuestions(polls[index].questions.map(({ question_id, mark }) => ({
      question: question_id,
      points: mark
    })));
    setIsPollModalOpen(true);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        الاستبيانات
      </Typography>
      {polls.map((poll, index) => (
        <Card key={index} variant="outlined" sx={{ mb: 2 }}>
          <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6">{poll.title}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link to={{ pathname: '/Questionnaire', search: '?id=' + poll.id + '&title=' + poll.title }} style={{ textDecoration: 'none' }}>
                <Button variant="outlined" sx={{ mr: 2 }}>مشاهدة</Button>
              </Link> 
              <IconButton onClick={() => deletePoll(index)} color="secondary">
                <DeleteIcon />
              </IconButton>
            </Box>     
            
          </CardContent>
        </Card>
      ))}
      
      <Button onClick={() => setIsPollModalOpen(true)} variant="contained" color="primary">
        اضافة استبيان جديد
      </Button>

      <Modal
        open={isPollModalOpen}
        onClose={() => setIsPollModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          overflow: 'hidden', // Ensure the modal itself doesn't scroll
    display: 'flex', // Use flex layout to better control child components
    alignItems: 'center', // Align items centrally for better aesthetics
    justifyContent: 'center' // Center the box horizontally
        }}
      >
        <Box sx={{
          width: 400, // Fixed width
          maxHeight: '80vh', // Max height to 80% of viewport height
          overflowY: 'auto', // Enable vertical scrolling within the box
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          position: 'relative', // Ensure positioning context is set for absolute children if any
               
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            {selectedPollIndex !== null ? 'Edit Poll' : 'اضافة استبيان جديد'}
          </Typography>
          <TextField
            fullWidth
            label="اسم الاستبيان"
            value={newPollName}
            onChange={(e) => setNewPollName(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          {pollQuestions?.map((pollQuestion, index) => (
            <Box key={index} mb={2}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id={`select-question-label-${index}`}>Question</InputLabel>
                <Select
                  labelId={`select-question-label-${index}`}
                  value={pollQuestion.question}
                  onChange={(e) => handlePollQuestionChange(index, 'question', e.target.value)}
                  label="الاسئلة"
                >
                  {questions?.map((question, qIndex) => (
                    <MenuItem key={qIndex} value={question.id}>{question.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {pollQuestion.question && (
                <TextField
                  fullWidth
                  label="Points"
                  value={pollQuestion.points}
                  onChange={(e) => handlePollQuestionChange(index, 'points', e.target.value)}
                  variant="outlined"
                  margin="normal"
                />
              )}
              <IconButton onClick={() => removePollQuestion(index)} color="secondary">
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={addPollQuestion} variant="outlined" startIcon={<AddCircleIcon />} sx={{ mt: 4, mb: 2,ml:4,mr:4 }}>
            اضافة سؤال جديد
          </Button>
          <Button onClick={selectedPollIndex !== null ? updatePoll : addPoll} variant="contained" color="primary" sx={{ mt: 2 }}>
          حفظ
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default Poll;
