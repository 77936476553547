import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { TextField, Box, Typography, Paper } from '@mui/material';

 export default function ErrorModal({ open, handleClose, errorMessage }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>{errorMessage}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
}
