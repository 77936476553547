import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Card, CardContent, Typography, Box, Modal, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

const apiUrl = 'https://api.sbk-questionnaire.com/api/questions';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

function Questions() {

  const [questions, setQuestions] = useState([]);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState(null);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(apiUrl);
      const formattedQuestions = response.data.data.map(question => ({
        ...question,
      
        saved: true
      }));
      console.log(formattedQuestions);
      setQuestions(formattedQuestions);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const addQuestion = () => {
    const newQuestion = {
      question: "",
      answers: [],
      correctAnswer: null,
      saved: false
    };
    setEditingQuestion(newQuestion);
    setModalOpen(true);
  };

  const markCorrectAnswer = (index) => {
    setCurrentAnswer((prevAnswer) => {
      if (prevAnswer && prevAnswer.index === index) {
      
        return { ...prevAnswer, is_correct: prevAnswer.is_correct === 1 ? 0 : 1 };
      } else {
        const answer = editingQuestion.answers[index];
        if (answer) {
       
          return { index, text: answer.title, is_correct: answer.is_correct };
        } else {
         
          return null;
        }
      }
    });
  };
  
    
  
  const handleOpenModal = (question) => {
    setEditingQuestion(question);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditingQuestion(null);
    setModalOpen(false);
  };

  const handleOpenSecondModal = (answer = null) => {
    setCurrentAnswer(answer);
    setSecondModalOpen(true);
  };

  const handleCloseSecondModal = () => {
    setCurrentAnswer(null);
    setSecondModalOpen(false);
  };

  const handleQuestionChange = (event) => {
    console.log(event.target.value); // Check the value being received
    setEditingQuestion({ ...editingQuestion, title: event.target.value });
  };

  const addAnswer = () => {
    setCurrentAnswer({ index: null, text: '', is_correct: 0 });
    handleOpenSecondModal();
  };
  
  const saveAnswer = async (text, isCorrect) => {
    const newAnswer = { id: (editingQuestion.answers || []).length + 1, text, is_correct: isCorrect };
    setEditingQuestion({ ...editingQuestion, answers: [...(editingQuestion.answers || []), newAnswer] });
  
    const formData = new FormData();
    formData.append('title', text);
    formData.append('is_correct', isCorrect ? 1 : 0); // Convert boolean to integer
    formData.append('question_id', editingQuestion.id); // Pass the selected question's ID

    try {
      await axios.post('https://api.sbk-questionnaire.com/api/answers', formData);
      fetchQuestions(); // Refresh the list after posting
      handleCloseModal();
    } catch (error) {
      console.error('Error posting question:', error);
    }
    handleCloseSecondModal();
  };
  
  // const updateAnswer = async (answerID, newData) => {
  //  console.log(newData,'newData')

  //   try {
  //     await axios.patch(`https://api.sbk-questionnaire.com/api/answers/${answerID}`, {
  //       title: newData.title,
  //       is_correct: newData.is_correct
  //     });
  //     fetchQuestions(); // Refresh the list after updating
  //   } catch (error) {
  //     console.error('Error updating answer:', error);
  //   }
  // };
  
  const handleSaveAnswer = () => {
    console.log(currentAnswer,'currentAnswer')
   
      saveAnswer(currentAnswer.text, currentAnswer.is_correct);
   
    handleCloseSecondModal();
    setModalOpen(false)
  };
  
  // const deleteAnswer = (index) => {
  //   const updatedAnswers = editingQuestion.answers.filter((_, i) => i !== index);
  //   setEditingQuestion({ ...editingQuestion, answers: updatedAnswers });
  // };

  const deleteAnswer = async () => {
    console.log(currentAnswer)
    try {
      await axios.delete(`https://api.sbk-questionnaire.com/api/answers/${currentAnswer.id}`);
      handleCloseSecondModal();
      setModalOpen(false)
      fetchQuestions(); // Refresh the list after deleting
    } catch (error) {
      console.error('Error deleting answer:', error);
    }
  };

  const postQuestion = async () => {
    const question = editingQuestion;
    if (question.title.trim()) {
      const formData = new FormData();
      formData.append('title', question.title);
      // Add other necessary fields for the new question
  
      try {
        await axios.post(apiUrl, formData);
        fetchQuestions(); // Refresh the list after posting
        handleCloseModal();
      } catch (error) {
        console.error('Error posting question:', error);
      }
    } else {
      alert('Please fill all fields and mark a correct answer before saving.');
    }
  };
  
  const updateQuestion = async () => {
    const question = editingQuestion;
    if ( question.id >=1) {
      const formData = new FormData();
      formData.append('title', question.title);
      // Add other necessary fields for the updated question
  
      try {
        await axios.patch(`${apiUrl}/${question.id}`, {'title': question.title});
        fetchQuestions(); // Refresh the list after updating
        handleCloseModal();
      } catch (error) {
        console.error('Error updating question:', error);
      }
    } else {
      postQuestion()
    }
  };
  const DeleteQuestion = async () => {
    const question = editingQuestion;
    if ( question.id >=1) {
     
    
  
      try {
        await axios.delete(`${apiUrl}/${question.id}`);
        fetchQuestions(); // Refresh the list after updating
        handleCloseModal();
      } catch (error) {
        console.error('Error Deleting question:', error);
      }
    } else {
      alert('Please select a question to Delte.');
    }
  };
  

  // const handleSaveAnswer = () => {
  //   if (currentAnswer) {
  //     updateAnswer(currentAnswer.index, currentAnswer.text);
  //   } else {
  //     saveAnswer(currentAnswer.text);
  //   }
  //   handleCloseSecondModal();
  // };

  // const handleSaveAnswer = () => {
  //   if (currentAnswer) {
  //     updateAnswer(currentAnswer.id, { title: currentAnswer.text });
  //   } else {
  //     saveAnswer(currentAnswer.text);
  //   }
  //   handleCloseSecondModal();
  // };
  
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        إدارة الاسئلة
      </Typography>
      {questions.map((question, index) => (
        <Card
          key={question?.id || index}
          variant="outlined"
          sx={{ marginBottom: 2, cursor: 'pointer' }}
          onClick={() => handleOpenModal(question)}
        >
          <CardContent>
            <Typography variant="h6">{question.title}</Typography>
          </CardContent>
        </Card>
      ))}
      <Button onClick={addQuestion} variant="contained" color="primary">
        اضافة سؤال 
      </Button>

      <Modal open={modalOpen} onClose={handleCloseModal}>
      <Box sx={modalStyle}>
      {editingQuestion && (
        <>
          <TextField
            fullWidth
            label="Question"
            value={editingQuestion.title}
            onChange={handleQuestionChange}
            variant="outlined"
            margin="normal"
        
          />
          {editingQuestion.title && (
            <Button onClick={() => DeleteQuestion()} variant="contained" color="secondary" sx={{ marginBottom: 2 }}>
              حذف
            </Button>
          )}
          <Button onClick={updateQuestion} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            حفظ
          </Button>
          <Typography variant="h6" gutterBottom>
            الاجوبة
          </Typography>
          {editingQuestion?.answers?.map((answer, idx) => (
            <Box key={idx} sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 1 }}>
              <Typography
                sx={{ cursor: 'pointer' }}
                onClick={() => handleOpenSecondModal({ index: idx, text: answer.title, is_correct: answer.is_correct, id: answer.id })}
              >
                {answer.title}
              </Typography>
              {currentAnswer?.index === idx ? (
                <>
                  <IconButton color="secondary" onClick={() => deleteAnswer(answer.id)}>
                    <DeleteIcon />
                  </IconButton>
                </>
              ) : null}
            </Box>
          ))}
          <Button onClick={addAnswer} variant="outlined" color="primary" sx={{ marginTop: 2 }}>
            اضافة جواب
          </Button>
        </>
      )}
    </Box>
    
    </Modal>



      <Modal open={secondModalOpen} onClose={handleCloseSecondModal}>
        <Box sx={modalStyle}>
          <TextField
            fullWidth
            label="Answer"
            value={currentAnswer?.text || ''}
            onChange={(e) => setCurrentAnswer({ ...currentAnswer, text: e.target.value })}
            variant="outlined"
            margin="normal"
          />
          {currentAnswer?.text !=='' &&currentAnswer?.text !==null &&currentAnswer?.text !==undefined  ?
          <div>
          { currentAnswer?.is_correct? 'صحيحة' : 'خاطئة'}
          </div>
          :
           
          <Button
          variant="contained"
          color={ currentAnswer?.is_correct ? 'success' : 'primary'}
          onClick={() =>  { setCurrentAnswer({ ...currentAnswer, is_correct: !currentAnswer?.is_correct  });console.log(currentAnswer)} }
        

          sx={{ marginTop: 2 }}
        >
          { currentAnswer?.is_correct? 'صحيحة ' : 'اجعلها صحيحة'}
        </Button>    
        }
         
        {currentAnswer?.id >=0  ?
          <IconButton color="secondary" onClick={() => deleteAnswer(currentAnswer?.index)} sx={{ marginTop: 2 }}>
          <DeleteIcon />
       
       
          </IconButton>:
                    <div></div>
                      }

          

            {currentAnswer?.id >=0  ?
<div></div>:
          <Button onClick={handleSaveAnswer} variant="contained" color="primary" sx={{ marginTop: 2 }}>
            حفظ
          </Button>
            }
        </Box>
      </Modal>
    </Box>
  );
}

export default Questions;
